label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}




/* Side nav bar code start */
.sidenav {
  height: 100%;
  width: 70px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #273746;
  overflow-x: hidden;
  padding-top: 20px;
  margin-top: 3.5rem!important;
  font-size: 1rem;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
}

.sidenav a:hover {
  color: #f1f1f1;
}


/* Side nav bar code end */




/* Table.css  start*/

.table-container {
  margin: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

a {
  text-decoration: none;
  color: #3498db;
}

button {
  padding: 8px 12px;
  cursor: pointer;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.button-primary {
  background-color: #3498db;
}

.button-secondary {
  background-color: #e74c3c;
}
.button-success {
  background-color: #229954;
}

.button-primary:hover, .button-secondary:hover .button-success:hover{
  background-color: #2980b9;
}

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333; /* Set your header background color */
  color: #fff; /* Set your header text color */
  padding: 10px 20px; /* Adjust padding as needed */
  z-index: 1000; /* Set a high z-index to ensure the header is above other elements */
}

/* Table.css end */




/* .table-container {
  margin: 20px;
}

.ant-table {
  width: 97%;
  margin: auto;
  border-bottom: 2px solid #e8e8e8; 
}

.ant-tag {
  font-size: 12px;
  padding: 4px 8px;
}

.header {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
} */