th {
    text-align: center;
}

td {
    text-align: center;
}

tr {
    margin-top: 100px;
}